$(window).scroll(function () {
  const scroll = $(window).scrollTop();
  const topImgWrapper = $("#js_topImgWrapper");
  const winW = $(window).width();
  const obj = $("#js_topText");
  const bk_white = $("#js_bkColor_white");
  const topImgscrolldown = $("#js_topImg_scrolldown");
  const scrolldown = $("#js_top_scrolldown");
  const zoom_rate = (scroll * 10) / 50;
  const youtubearea = $("#ly_youtubeArea");
  const youtubearea_position = youtubearea.offset().top;
  if (scroll <= 50) {
    obj.css({ transform: "scale(1)" });
    topImgWrapper.addClass("hp_zindexUnset").removeClass("hp_zindexZero");
    topImgscrolldown.removeClass("hp_displayBlock");
    scrolldown.removeClass("hp_displayNone");
  } else {
    obj.css({ transform: "scale" + "(" + zoom_rate + ")" });
    topImgWrapper.removeClass("hp_zindexUnset").addClass("hp_zindexZero");
    topImgscrolldown.addClass("hp_displayBlock");
    scrolldown.addClass("hp_displayNone");
    if (winW <= 500) {
      obj.css({ transform: "scale" + "(" + zoom_rate * 1.2 + ")" });
    }
  }
  if (scroll >= 1500) {
    bk_white.addClass("hp_displayNone");
  } else {
    bk_white.removeClass("hp_displayNone");
  }
  if (scroll > youtubearea_position) {
    topImgWrapper.addClass("active");
    topImgscrolldown.removeClass("hp_displayBlock");
  } else {
    topImgWrapper.removeClass("active");
  }
});

function playVideos(videos) {
  const scroll = $(window).scrollTop();
  const windowInnerHeight = window.innerHeight;
  const windowInnerHeight2 = windowInnerHeight / 2;
  videos.each(function () {
    const videoTop = $(this).offset().top;
    const videoHeight = $(this).innerHeight();
    if ($(this).get(0).paused && scroll > videoTop - windowInnerHeight + windowInnerHeight2) {
      $(this).parent().find("#bl_youtubeLink").addClass("hp_backgroundNone");
      $(this).get(0).autoplay = true;
      $(this).get(0).play();
    }
    if (
      !$(this).get(0).paused &&
      (scroll < videoTop - windowInnerHeight + windowInnerHeight2 ||
        scroll > videoTop - windowInnerHeight + windowInnerHeight2 + videoHeight)
    ) {
      $(this).parent().find("#bl_youtubeLink").removeClass("hp_backgroundNone");
      $(this).get(0).autoplay = false;
      $(this).get(0).pause();
    }
  });
}
$(window).on("load", function () {
  const videos = $("#ly_youtubeArea #bl_youtube");
  if (videos.length) {
    playVideos(videos);
    $(window).on("scroll", function () {
      playVideos(videos);
    });
  }
});
